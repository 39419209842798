import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; 
import cybertrends from '../../assets/img/blogs_image/cyber_security_trends.jpg';
import Dataprivacy from '../../assets/img/blogs_image/dataprivacy.jpg';
import Dataencrypt from '../../assets/img/blogs_image/data_encryption.jpg';
import Toptrends from '../../assets/img/blogs_image/prodction.jpg';
import Futuretrends from '../../assets/img/blogs_image/future_cyber.jpg';
import TopNavbar from "../pages/Navbar";
import Footer from "../pages/Footer2";
import BackgroundImage from '../../assets/img/add/new_bg_ai.png';

export default function Blog() {
  const navigate = useNavigate(); 

  const blogBoxData = [
    {
      title: "Cyber Security Trends",
      text: "Understanding the latest trends in cybersecurity.",
      tag: "company",
      author: "yuvendhan, 5 days ago",
      link: "/cybers", 
      img: cybertrends,
    },
    {
      title: "Data Privacy",
      text: "The importance of data privacy in the digital age.",
      tag: "company",
      author: "Deepadharsan, 5 days ago",
      link: "/data", 
      img: Dataprivacy,
    },
    {
      title: "Future Trends",
      text: "Exploring future trends in technology.",
      tag: "company",
      author: "Akash, 6 days ago",
      link: "/future", 
      img: Futuretrends,
    },
    {
      title: "Role of Encryption",
      text: "How encryption plays a critical role in data security.",
      tag: "company",
      author: "Akash, 6 days ago",
      link: "/role", 
      img: Dataencrypt,
    },
    {
      title: "Top Trends",
      text: "Highlighting the top trends in cybersecurity.",
      tag: "company",
      author: "Selva, 10 days ago",
      link: "/top", 
      img: Toptrends,
    }
  ];

  const handleBoxClick = (link) => {
    navigate(link);
  };

  return (
    <>
     <TopNavbar />
      <Wrapper id="blog">
        <Container>
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Blog Stories</h1>
            
          </HeaderInfo>
          <BlogGrid>
            {blogBoxData.map((blog, index) => (
              <BlogCard
                key={index}
                onClick={() => handleBoxClick(blog.link)}
              >
                <img src={blog.img} alt={blog.title} />
                <div className="card-content">
                  <h3>{blog.title}</h3>
                  <p>{blog.text}</p>
                  <p className="author">{blog.author}</p>
                </div>
              </BlogCard>
            ))}
          </BlogGrid>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  color: #000;
margin-top:55px;

   background-image: url(${BackgroundImage}); // Set the background image
  background-size: cover; // Ensure the image covers the entire area
  background-position: center;
  background-attachment: fixed;

  @media (max-width: 768px) {
    background-attachment: scroll; // Remove parallax effect on smaller devices
  }
`;

const Container = styled.div`
margin-top: 50%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
 
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;


  h1 {
    color: #fff;
        font-family: Cake sans;
         line-height:1.5;
  }

  p {
    font-size: 18px;
        font-family: Cake sans;
        line-height:1.5;
    color: #000;
  }
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const BlogCard = styled.div`
  width: 100%;
  max-width: 300px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-out;

  img {
    width: 100%;
    height: 180px;
    object-fit: contain;
  }

  .card-content {
    padding: 15px;
    text-align: center;

    h3 {
      margin: 10px 0;
      font-size: 1.2em;
        font-family: Cake sans;
    }

    p {
      margin: 5px 0;
      font-size: 0.9em;
        font-family: Cake sans;
    }

    .author {
      color: #888;
      font-size: 0.8em;
        font-family: Cake sans;
    }
  }

  &:hover {
    transform: scale(1.02);
  }
`;