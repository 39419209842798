import React from 'react';
import TopNavbar from '../pages/Navbar';
import Footer from '../pages/Footer2';
import '../pages/style.css';

const RazorpayPayment = (e) => {
  e.preventDefault();

  // Ensure the Razorpay script is included in your index.html
  if (!window.Razorpay) {
    alert('Razorpay SDK failed to load. Are you online?');
    return;
  }

  const options = {
    key: 'rzp_live_xAPYPcMAEMICyU',
    amount: "399900", // Set amount here in currency subunits
    currency: 'INR',
    name: 'ES Ethicsecur',
    description: 'Razorpay Online Payment',
    handler: function (response) {
      alert(response.razorpay_payment_id);
    },
    prefill: {
      name: 'ES Ethicsecur',
      email: 'test@test.com',
      contact: '9876543210',
    },
    notes: {
      address: 'Razorpay corporate office',
    },
    theme: {
      color: '#00b0ff',
    }
  };

  const pay = new window.Razorpay(options);
  pay.open();
}

const Pricing = () => {
  return (
    <>
      <div className="banner-area1">
        <h2>Pricing</h2>
      </div>
      <TopNavbar />
      <section id="pricing" className='image'>
        <br />
        <div className="container center">
          <div className="row mt-5 justify-center">
            <div className="col-12">
              <div className="content">
                <h2>Our Services start from Rs. 3999</h2>
                <ol>
                  <li>
                    <h3>Network Security Solution</h3>
                    <p>
                      Guarding Your Connectivity: Discover our cutting-edge network security solutions designed to provide unwavering protection. We offer advanced technologies and strategies to safeguard your network from threats, ensuring uninterrupted connectivity and peace of mind.
                    </p>
                  </li>
                  <li>
                    <h3>Data Encryption Services</h3>
                    <p>
                      Securing Tomorrow's Information: Explore our comprehensive data encryption services designed to deliver unrivaled privacy and protection. We employ advanced encryption technologies to safeguard your data, ensuring secure handling and peace of mind.
                    </p>
                  </li>
                  <li>
                    <h3>Cyber Threat Monitoring</h3>
                    <p>
                      Shielding the Digital Frontier: Experience our advanced cyber threat protection solutions, delivering unmatched security against evolving threats. We employ cutting-edge technologies to safeguard your digital assets, ensuring robust protection and peace of mind.
                    </p>
                  </li>
                  <li>
                    <h3>Marketing Services</h3>
                    <p>
                      Elevate Your Brand: Unlock strategic marketing solutions crafted for unrivaled impact. We offer tailored strategies and innovative approaches to enhance your brand's visibility and drive meaningful engagement.
                    </p>
                  </li>
                  <li>
                    <h3>Web Development</h3>
                    <p>
                      Empowering Your Online Presence: Harness expert web development services to propel your digital presence forward. We specialize in innovative solutions to optimize your website for superior performance and user engagement.
                    </p>
                  </li>
                  <li>
                    <h3>Production Support</h3>
                    <p>
                      Uninterrupted Operations, Unmatched Support: Elevate your production capabilities with our dedicated support services. We provide reliable, round-the-clock assistance to ensure seamless operations and sustained productivity.
                    </p>
                  </li>
                </ol>
                <button id="button" onClick={RazorpayPayment}>Continue to Checkout</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Pricing;
